import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import ReactReadMoreReadLess from "react-read-more-read-less"


const Temoignages = () => {
  return (
    <Portfolio>
    <HeroItemsH2>  Pourquoi m'ont-ils choisie? </HeroItemsH2>

    <BioContainerFlex>
    <BioImage>
        <StaticImage
        src="../assets/images/Choisir la Lumière.jpg"
        width={500}
        alt="Bio"
        placeholder="tracedSVG"
        quality={40}
        />
    </BioImage>
    <Bio>
    <h3></h3>
    <ReactReadMoreReadLess
    readMoreClassName="readMoreClassName"
    charLimit={509}
    readMoreText={"Lire la suite ▼"}
    readLessText={" ▲"}
    >
    “Travail remarqué par des gens qui ont l’occasion de fréquenter ce type de site pour leur travail !
    Nous sommes fières de ce site, à la fois sobre et très clair. La dimension visuelle renforce notre  message, ce qui nous a été confirmé par quelques retours de visiteurs." 
    
    </ReactReadMoreReadLess>
    <h3></h3>
    <ReactReadMoreReadLess
    readMoreClassName="readMoreClassName"
    charLimit={59}
    readMoreText={"Lire la suite ▼"}
    readLessText={" ▲"}
    >
    Voici celui de Nathalie T. après sa 1ere visite sur le site :
    “J'ai visité le site : sobre, épuré, délicat et franc en même temps, concis, allant à l'essentiel. En peu de temps, on comprend tout, les objectifs-mission sont clairs, les témoignages explicites à travers l'implicite, les photos sont des messages suggestifs doux.
    Je le ressens courageux, osé, car la vérité est dite sans détour. 
    Il est aussi une belle invitation à libérer la parole, sans prendre de risque si besoin.”
  
    </ReactReadMoreReadLess>
<Bio>
    <ReactReadMoreReadLess
    readMoreClassName="readMoreClassName"
    charLimit={140}
    readMoreText={"Lire la suite ▼"}
    readLessText={" ▲"}
    >

    Regina a compris nos motivations et elles transparaissent clairement à travers les propositions techniques qu’elles nous a faites : architecture, effets visuels notamment sur la page d’accueil, modalités d’invitation aux lecteurs à prendre contact avec nous … 
    L’accompagnement précis de Regina, respectueux et patient, nous a permis d’avancer efficacement pour mettre nos idées en paroles et images dans les délais. 
    Maintenant Regina nous aide à prendre en mains la partie blog et la mise à jour globale du site. Un vrai service clés en mains !
    Nous sommes heureuses de recommander chaleureusement cette professionnelle, fiable, disponible et rigoureuse dans les délais.“
    Hier nous avions RV avec 2 journalistes nous demandant : "Qui a fait votre site ? Il est très clair, sobre, et il est beau !"
    </ReactReadMoreReadLess>
    <h3></h3>
    <ReactReadMoreReadLess
    readMoreClassName="readMoreClassName"
    charLimit={140}
    readMoreText={"Lire la suite ▼"}
    readLessText={" ▲"}
    >
    Claire, avec Annie, Béatrice et Martine
    </ReactReadMoreReadLess>
   
    </Bio>
      </Bio>
</BioContainerFlex>

<BioContainerFlex>
<BioImage>
    <StaticImage
    src="../assets/images/kine-1.jpg"
    width={500}
    alt="Bio"
    placeholder="tracedSVG"
    quality={40}
    />
</BioImage>
<Bio>
<h3></h3>
  <ReactReadMoreReadLess
  readMoreClassName="readMoreClassName"
  charLimit={308}
  readMoreText={"Lire la suite ▼"}
  readLessText={" ▲"}
  >
  "Regina m'a crée 2 sites : un site pro pour mon cabinet médical et un site artistique pour me lancer dans mon projet d'art qui me tiens beaucoup à coeur .
       Mon premier site est installé maintenant depuis plus d'un an : il est très clair, fonctionnel et très utilisé par mes patients avec un excellent retour.
       Grace à ce site et doctolib ( dont Régina m'a accompagné pour m'y connecter ) , j'ai une plus grande liberté de travail,  
       je peux me concentrer plus sur l'acte de traitement en soi sans devoir réexpliquer comment je travaille. 
       Les patients sont preparés dès qu'ils arrivent au cabinet pour recevoir mes soins proposés"
       </ReactReadMoreReadLess>
       <h3></h3>
  <ReactReadMoreReadLess
  readMoreClassName="readMoreClassName"
  charLimit={290}
  readMoreText={"Lire la suite ▼"}
  readLessText={" ▲"}
  >
      "J'en suis très heureuse et très reconnaissante à Regina."
  </ReactReadMoreReadLess>
  </Bio>
</BioContainerFlex>

<BioContainerFlex>
<BioImage>
    <StaticImage
    src="../assets/images/CG-art.jpg"
    width={500}
    alt="Bio"
    placeholder="tracedSVG"
    quality={40}
    />
</BioImage>
<Bio>
  
  <ReactReadMoreReadLess 
  charLimit={290}

  readMoreText={"Lire la suite ▼"}
  readLessText={" ▲"}
  >"Le deuxième site, créé par Regina a été mis en service récemment après une recherche assidue et un accompagnement professionnel de sa part comme Designer pour pouvoir me profiler le mieux possible dans ma démarche artistique donc je suis encore une fois très reconnaissante.
  Je me retrouve complètement dans la création de ce site . Depuis qu'il est en service, j'ai de très bons retours. Ce site me laisse encore une fois une liberté pour me presenter le mieux possible: mes expositions , ma façon de travailler,  mon instagram."
  </ReactReadMoreReadLess>
  <h3></h3>
  <ReactReadMoreReadLess
  readMoreClassName="readMoreClassName"
  charLimit={100}
  readMoreText={"Lire la suite ▼"}
  readLessText={" ▲"}
  >
  Merci beaucoup Regina !!! .
  </ReactReadMoreReadLess>
  </Bio>
</BioContainerFlex>
    </Portfolio>
  )
}

export default Temoignages

const Portfolio = styled.div`

      @media screen and (max-width: 768px) {

      }
`
const HeroItemsH2 = styled.div` 
    font-size: 6rem; 
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
    padding: 2rem 5rem;
    text-align: center;
      @media screen and (max-width: 768px) {
          padding: 0rem 0.5rem;
          font-size: 3rem; 
          margin-top: 2.5rem;
        }
`

const BioContainerFlex = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5rem ;
  color: rgba(169, 73, 69, 0.636);
  background-color: #404640;  
      @media screen and (max-width: 768px) {
        flex-direction: column;
        margin: 0rem ;
        margin-top: 5rem ;
        background-color: white;  
  `
const BioImage = styled.div`
  flex: 50%;
  text-align: center;
  heigth: auto;
  display: block;
  margin-top: 5rem ;
      @media screen and (max-width: 768px) {
        margin-top: 0rem ;
      }
`
const Bio = styled.div`
  flex: 50%;
  padding: 6rem;
  font-size: 1.2rem;
  color: white;
  padding-top: 2rem;
  padding-right: 5rem;
  text-align: center;
      h3{
        padding: 1rem;
      }
      ReactReadMoreReadLess{
        color:blue;
      }
      @media screen and (max-width: 768px) {
        color:black;
        text-align: center;
        font-size: 1.2rem;
        padding: 2rem;
        h3 {
          padding: 1rem;
          }
            }
          
`